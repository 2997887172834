import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: "Home",
        about: "About Us",
        products: "Products",
        gallery: "Gallery",
        articles: "Articles",
        partners: "Partners",
        all: "All",
        noImage: "No Image Available",
        noName: "No Name Available",
        loading: "Loading...",
        productNotFound: "Product not found",
        errorLoadingData: "Error loading data",
        noProductData: "Product data is not available.",
        properties: "Properties",
        addInfo: "How to use",
        noInformationAvailable: "No information available",
        unsupportedContentFormat: "Unsupported content format",
        other: "Other",
        close: "Close",
        noDescription: "No description available.",
        readMore: "Read More",
        partnersTitle: "Our Partners",
        noPartners: "No partner images available or failed to load.",
        galleryTitle: "Gallery",
        noGalleryItems: "No gallery items available.",
        imageModalClose: "Close Image",
        info: "Information",
        best_products: "Best products",
        no_name: "No name",
        no_image: "No image available",
        no_best_products: "No best products found"
      }
    },
    ru: {
      translation: {
        home: "Главная",
        about: "О нас",
        products: "Продукты",
        gallery: "Галерея",
        articles: "Статьи",
        partners: "Партнеры",
        all: "Все",
        noImage: "Изображение отсутствует",
        noName: "Название отсутствует",
        loading: "Загрузка...",
        productNotFound: "Продукт не найден",
        errorLoadingData: "Ошибка загрузки данных",
        noProductData: "Данные о продукте недоступны.",
        properties: "Характеристики",
        addInfo: "Способ применения",
        noInformationAvailable: "Информация отсутствует",
        unsupportedContentFormat: "Неподдерживаемый формат контента",
        other: "Остальное",
        close: "Закрыть",
        title: "Статьи",
        noDescription: "Описание отсутствует.",
        readMore: "Подробнее",
        partnersTitle: "Наши Партнеры",
        noPartners: "Изображения партнёров отсутствуют или не загружаются.",
        galleryTitle: "Галерея",
        noGalleryItems: "Элементы галереи отсутствуют.",
        imageModalClose: "Закрыть",
        info: "Информация",
        best_products: "Лучшие товары",
        no_name: "Название отсутствует",
        no_image: "Изображение отсутствует",
        no_best_products: "Нет лучших товаров в данный момент."
      }
    },
    kk: {
      translation: {
        home: "Басты бет",
        about: "Бренд туралы",
        products: "Өнімдер",
        gallery: "Галерея",
        partners: "Серіктестер",
        all: "Барлығы",
        noImage: "Сурет жоқ",
        noName: "Атауы жоқ",
        loading: "Жүктелуде...",
        productNotFound: "Өнім табылмады",
        errorLoadingData: "Деректерді жүктеу қатесі",
        noProductData: "Өнім туралы деректер қол жетімді емес.",
        properties: "Сипаттамалар",
        addInfo: "Қолдану әдісі",
        noInformationAvailable: "Ақпарат жоқ",
        unsupportedContentFormat: "Қолдау көрсетілмейтін мазмұн форматы",
        other: "Басқалар",
        close: "Жабу",
        title: "Мақалалар",
        noDescription: "Сипаттама жоқ.",
        readMore: "Толығырақ",
        partnersTitle: "Серіктестеріміз",
        noPartners: "Серіктестердің суреттері қолжетімді емес немесе жүктелмейді.",
        galleryTitle: "Галерея",
        noGalleryItems: "Галерея элементтері жоқ.",
        imageModalClose: "Суретті жабу",
        info: "Ақпарат",
        best_products: "Үздік өнімдер",
        no_name: "No name",
        no_image: "No image available",
        no_best_products: "Қазіргі уақытта үздік өнімдер жоқ."
      }
    }
  },
  lng: "ru",
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
