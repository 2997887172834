import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Products.css';

function Products() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedClass, setSelectedClass] = useState(t('all')); 
  const [uniqueClasses, setUniqueClasses] = useState([]);
  const itemsPerPage = 20;

 
  useEffect(() => {
    axios.get(`${API_URL}/api/products?populate=*&locale=${i18n.language}`)
      .then(response => {
        const productsData = response.data.data;
        setProducts(productsData);

        const classes = [...new Set(productsData.map(product => product.class).filter(Boolean))];
        setUniqueClasses([t('all'), ...classes]);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [i18n.language, t]);

 
  useEffect(() => {
    setSelectedClass(t('all'));
  }, [i18n.language, t]);

  const filteredProducts = selectedClass === t('all')
    ? products
    : products.filter(product => product.class === selectedClass);

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredProducts.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleClassClick = (className) => {
    setSelectedClass(className);
    setCurrentPage(0);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">{t('products')}</h2>
      <div className="mb-4">
        {uniqueClasses.map((className, index) => (
          <button
            key={index}
            className={`custom-btn ${selectedClass === className ? 'custom-btn-selected' : ''} me-2 mb-2`}
            onClick={() => handleClassClick(className)}
          >
            {className}
          </button>
        ))}
      </div>

      <div className="row">
        {currentItems.map((product, index) => {
          const id = product.id;
          const name = product.name || t('noName');
          const productClass = product.class || 'Uncategorized';

          const imageUrl = product.image?.formats?.medium?.url
            ? `${API_URL}${product.image.formats.medium.url}`
            : product.image?.formats?.small?.url
            ? `${API_URL}${product.image.formats.small.url}`
            : null;


          return (
            <div key={id || index} className="col-6 col-md-4 col-lg-3 col-xl-2 mb-4">
              <Link to={`/products/${id}`} className="text-decoration-none text-dark">
                <div className="card h-100 shadow-sm">
                  {imageUrl ? (
                    <img src={imageUrl} alt={name} className="card-img-top" style={{ height: '250px', objectFit: 'cover' }} />
                  ) : (
                    <div className="card-img-top text-center p-2">{t('noImage')}</div>
                  )}
                  <div className="card-body text-center">
                    <h5 className="card-title">{name}</h5>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>

      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-4'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </div>
  );
}

export default Products;
