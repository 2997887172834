import React from 'react';
import HeaderImage from '../components/HeaderImage';


function Home() {
  
  return (
    <div className="container mt-5">
      <HeaderImage />
    </div>
  );
}

export default Home;
