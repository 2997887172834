import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Footer.css';

function Footer() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const [footerIconUrl, setFooterIconUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showInfoButton, setShowInfoButton] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const fetchFooterIcon = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/main-icons?populate=*`);
        const iconUrl = response.data.data[0]?.icon?.url;
        setFooterIconUrl(`${API_URL}${iconUrl}`);
      } catch (error) {
        console.error('Error fetching footer icon:', error);
      }
    };

    fetchFooterIcon();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.documentElement.scrollHeight;

      if (isSmallScreen && scrollPosition >= bottomPosition - 10) {
        setShowInfoButton(true);
      } else {
        setShowInfoButton(false);
      }
    };

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize); // Recalculate on resize
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {/* Standard Footer Visible on All Screens */}
      <footer className="footer bg-light py-4">
        <Container className="text-center">
          <Row className="d-flex justify-content-center">
            <Col xs="auto">
              {footerIconUrl ? (
                <a href="https://drkang.kz" target="_blank" rel="noopener noreferrer">
                  <img src={footerIconUrl} alt="Footer Icon" className="footer-icon mb-2" />
                </a>
              ) : (
                'Loading...'
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs="auto">
              <a href="https://drkang.kz" target="_blank" rel="noopener noreferrer" className="text-dark">
                www.drkang.kz
              </a>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-3">
            <Col xs="auto">
              <a href="https://www.instagram.com/drkang.kz" target="_blank" rel="noopener noreferrer" className="text-dark mx-2">
                <i className="bi bi-instagram" style={{ fontSize: '1.5rem' }}></i>
                Instagram
              </a>
            </Col>
            <Col xs="auto">
              <a href="mailto:info@drkang.kz" className="text-dark mx-2">
                <i className="bi bi-envelope-fill" style={{ fontSize: '1.5rem' }}></i>
                koreanshopks@gmail.com
              </a>
            </Col>
          </Row>
        </Container>
      </footer>

      
      {isSmallScreen && showInfoButton && (
        <Button 
          variant="info" 
          className="info-button" 
          onClick={handleShowModal}
        >
          <i className="bi bi-info-circle"></i>
        </Button>
      )}

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('info')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="text-center">
            <Row className="d-flex justify-content-center">
              <Col xs="auto">
                {footerIconUrl ? (
                  <a href="https://drkang.kz" target="_blank" rel="noopener noreferrer">
                    <img src={footerIconUrl} alt="Footer Icon" className="footer-icon mb-2" />
                  </a>
                ) : (
                  'Loading...'
                )}
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col xs="auto">
                <a href="https://drkang.kz" target="_blank" rel="noopener noreferrer" className="text-dark">
                  www.drkang.kz
                </a>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-3">
              <Col xs="auto">
                <a href="https://www.instagram.com/drkang.kz" target="_blank" rel="noopener noreferrer" className="text-dark mx-2">
                  <i className="bi bi-instagram" style={{ fontSize: '1.5rem' }}></i>
                  Instagram
                </a>
              </Col>
              <Col xs="auto">
                <a href="mailto:info@drkang.kz" className="text-dark mx-2">
                  <i className="bi bi-envelope-fill" style={{ fontSize: '1.5rem' }}></i>
                  koreanshopks@gmail.com
                </a>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Footer;
