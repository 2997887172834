import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.css';

const AboutUs = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { i18n } = useTranslation();
  const [aboutUsData, setAboutUsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/aboutuses?populate=*&locale=${i18n.language}`);
        console.log("Full API Response:", response.data); 
        const sortedData = response.data.data.sort((a, b) => {
          const orderA = a.order || 0;
          const orderB = b.order || 0;
          return orderA - orderB;
        });
        setAboutUsData(sortedData);
      } catch (error) {
        console.error('Error fetching About Us data:', error);
      }
    };

    fetchData();
  }, [i18n.language, API_URL]); 

  const renderListItems = (items) => {
    return items.map((listItem, index) => (
      <li key={index}>
        {listItem.children.map((child, j) => renderTextStyles(child, j))}
      </li>
    ));
  };

  const renderTextContent = (textArray) => {
    return textArray.map((block, i) => {
      switch (block.type) {
        case 'heading':
          const HeadingTag = `h${block.level || 2}`;
          return (
            <HeadingTag key={i}>
              {block.children.map((child, j) => renderTextStyles(child, j))}
            </HeadingTag>
          );
        case 'paragraph':
          return (
            <p key={i}>
              {block.children.map((child, j) => renderTextStyles(child, j))}
            </p>
          );
        case 'list':
          if (block.format === 'unordered') {
            return (
              <ul key={i}>
                {renderListItems(block.children)}
              </ul>
            );
          } else if (block.format === 'ordered') {
            return (
              <ol key={i}>
                {renderListItems(block.children)}
              </ol>
            );
          }
          break;
        default:
          console.warn("Unknown block type:", block.type);
          return null;
      }
    });
  };

  const renderTextStyles = (child, key) => {
    let formattedText = child.text;

    if (child.bold) {
      formattedText = <strong key={key}>{formattedText}</strong>;
    }
    if (child.italic) {
      formattedText = <em key={key}>{formattedText}</em>;
    }
    if (child.underline) {
      formattedText = <u key={key}>{formattedText}</u>;
    }

    return <React.Fragment key={key}>{formattedText}</React.Fragment>;
  };

  return (
    <Container className="mt-5 about-us-section">
      {aboutUsData.map((item, index) => {
        const imageUrl = item.image?.url
          ? `${API_URL}${item.image.url}`
          : null;

        const textContent = item.text ? renderTextContent(item.text) : 'No text available.';

        return (
          <Row key={item.id} className={`mb-5 align-items-center ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
            <Col xs={12} md={6} className="d-flex justify-content-center position-relative">
              {imageUrl ? (
                <div className="image-container">
                  <img
                    src={imageUrl}
                    alt="About Us"
                    className="img-fluid rounded about-us-image"
                    style={{ width: '100%', objectFit: 'cover' }}
                  />
                  {/* Decorative shapes */}
                  <div className={`decorative-shape shape-${index + 1}`} />
                </div>
              ) : (
                <div className="placeholder about-us-placeholder" style={{ backgroundColor: '#ccc', width: '80%' }}>
                  No image available
                </div>
              )}
            </Col>
            <Col xs={12} md={6} className="text-md-left justify-text">
              <div className="about-us-text" style={{ margin: '0', lineHeight: '1.5' }}>
                {textContent}
              </div>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default AboutUs;
