import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './BestProducts.css';

function BestProducts() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t, i18n } = useTranslation();
  const [bestProducts, setBestProducts] = useState([]);

  useEffect(() => {
    axios.get(`${API_URL}/api/products?populate=*&locale=${i18n.language}`)
      .then(response => {
        const productsData = response.data.data;
        const bestProductsData = productsData.filter(product => product.bestproduct === true);
        setBestProducts(bestProductsData);
      })
      .catch(error => console.error('Error fetching best products:', error));
  }, [i18n.language]); 

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">{t('best_products')}</h2>
      {bestProducts.length > 0 ? (
        <Slider {...settings}>
          {bestProducts.map(product => {
            const id = product.id;
            const name = product.name || t('no_name');
            const imageUrl = product.image?.formats?.medium?.url
            ? `${API_URL}${product.image.formats.medium.url}`
            : product.image?.formats?.small?.url
            ? `${API_URL}${product.image.formats.small.url}`
            : null;

            return (
              <div key={id} className="p-2">
                <Link to={`/products/${id}`} className="text-decoration-none text-dark">
                  <div className="card h-100">
                    {imageUrl ? (
                      <img src={imageUrl} alt={name} className="card-img-top" />
                    ) : (
                      <div className="card-img-top text-center p-2">{t('no_image')}</div>
                    )}
                    <div className="card-body">
                      <h5 className="card-title">{name}</h5>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </Slider>
      ) : (
        <p>{t('no_best_products')}</p>
      )}
    </div>
  );
}

export default BestProducts;
