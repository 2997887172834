import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import './HeaderImage.css';

const HeaderImage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/home-images?populate=*`);
        console.log("Full API Response:", response.data); 

        
        const imageData = response.data.data[0]?.image?.url;
        if (imageData) {
          const fullImageUrl = `${API_URL}${imageData}`;
          setImageUrl(fullImageUrl);
        } else {
          console.error('Image data not found in the response');
        }
      } catch (error) {
        console.error('Error fetching home image:', error);
      }
    };

    fetchImage();
  }, []);

  return (
    <Container className="header-image-container">
      {imageUrl ? (
        <img src={imageUrl} alt="Dr. Kang" className="header-image" />
      ) : (
        <p>Loading...</p>
      )}
    </Container>
  );
};

export default HeaderImage;

