import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; 
import './Partners.css';

const Partners = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t } = useTranslation(); 
  const [partnersData, setPartnersData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/partners?populate=*`)
      .then(response => {
        const partners = response.data.data.map(partner => {
          const imageUrl = partner.partnerimage?.url
            ? `${API_URL}${partner.partnerimage.url}`
            : null;
          const linkUrl = partner.link || '#';
          return { ...partner, imageUrl, linkUrl };
        });
        setPartnersData(partners);
      })
      .catch(error => {
        console.error('Ошибка при получении данных партнёров:', error);
      });
  }, []);

  return (
    <Container className="partners-container">
      <h2 className="mb-4">{t('partnersTitle')}</h2> 
      <Row className="partners-row">
        {partnersData.length > 0 ? (
          partnersData.map((partner, index) => (
            <Col
              key={partner.id}
              xs={6} sm={6} md={4} lg={3}
              className="d-flex justify-content-center mb-4"
            >
              {partner.imageUrl ? (
                <a href={partner.linkUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={partner.imageUrl}
                    alt={`Partner ${index + 1}`}
                    className="partners-image"
                  />
                </a>
              ) : (
                <div className="placeholder">No image available</div>
              )}
            </Col>
          ))
        ) : (
          <p>Изображения партнёров отсутствуют или не загружаются.</p>
        )}
      </Row>
    </Container>
  );
};

export default Partners;
