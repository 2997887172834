import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './ProductDetails.css';

function ProductDetails() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { productId } = useParams();
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [activeTab, setActiveTab] = useState('properties');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${API_URL}/api/products?filters[id][$eq]=${productId}&populate=*&locale=${i18n.language}`)
      .then(response => {
        const foundProduct = response.data.data[0];
        if (foundProduct) {
          setProduct(foundProduct);
          setMainImage(foundProduct.image?.url);
        } else {
          setError(t('productNotFound'));
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading product data:', error);
        setError(t('errorLoadingData'));
        setLoading(false);
      });
  }, [productId, i18n.language, t]);

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{error}</p>;

  if (!product) {
    return <p>{t('noProductData')}</p>;
  }

  const { name, description, image, addimages, properties, ingredients, addinfo } = product;

  const handleThumbnailClick = (url) => {
    setMainImage(url);
  };

  const renderRichTextContent = (content) => {
    if (!content) return t('noInformationAvailable');

    if (Array.isArray(content)) {
      return content.map((item, index) => {
        switch (item.type) {
          case 'paragraph':
            return (
              <p key={index}>
                {item.children.map((child, childIndex) => {
                  if (child.bold) return <strong key={childIndex}>{child.text}</strong>;
                  if (child.italic) return <em key={childIndex}>{child.text}</em>;
                  if (child.underline) return <u key={childIndex}>{child.text}</u>;
                  return <span key={childIndex}>{child.text}</span>;
                })}
              </p>
            );
          case 'heading':
            const HeadingTag = `h${item.level || 1}`;
            return (
              <HeadingTag key={index}>
                {item.children.map((child, childIndex) => {
                  if (child.bold) return <strong key={childIndex}>{child.text}</strong>;
                  if (child.italic) return <em key={childIndex}>{child.text}</em>;
                  return <span key={childIndex}>{child.text}</span>;
                })}
              </HeadingTag>
            );
          case 'list':
            const ListTag = item.ordered ? 'ol' : 'ul';
            return (
              <ListTag key={index}>
                {item.children.map((listItem, listIndex) => (
                  <li key={listIndex}>
                    {listItem.children.map((child, childIndex) => {
                      if (child.bold) return <strong key={childIndex}>{child.text}</strong>;
                      if (child.italic) return <em key={childIndex}>{child.text}</em>;
                      return <span key={childIndex}>{child.text}</span>;
                    })}
                  </li>
                ))}
              </ListTag>
            );
          default:
            return <span key={index}>[Unsupported content type]</span>;
        }
      });
    }

    if (typeof content === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }

    return <p>{t('unsupportedContentFormat')}</p>;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'properties':
        return renderRichTextContent(properties);
      case 'addinfo':
        return renderRichTextContent(addinfo);
      default:
        return null;
    }
  };

  return (
    <div className="container mt-5 product-details-container">
      <div className="product-content d-flex flex-column flex-md-row align-items-start">
        <div className="product-images d-flex flex-column align-items-center align-items-md-start me-md-5">
          <div className="main-image mb-3">
            {mainImage ? (
              <img
                src={`${API_URL}${mainImage}`}
                alt={name}
                className="img-fluid main-image-style"
              />
            ) : (
              <p>{t('noImage')}</p>
            )}
          </div>
          <div className="thumbnails d-flex flex-wrap justify-content-center">
            {image && image.url && (
              <img
                src={`${API_URL}${image.url}`}
                alt="Main Thumbnail"
                className={`thumbnail mx-2 ${mainImage === image.url ? 'active-thumbnail' : ''}`}
                onClick={() => handleThumbnailClick(image.url)}
              />
            )}
            {addimages && addimages.map((img, index) => (
              <img
                key={index}
                src={`${API_URL}${img.url}`}
                alt={`Additional ${index + 1}`}
                className={`thumbnail mx-2 ${mainImage === img.url ? 'active-thumbnail' : ''}`}
                onClick={() => handleThumbnailClick(img.url)}
              />
            ))}
          </div>
        </div>

        <div className="product-details mt-4 mt-md-0 description-wrapper">
          <h1 className="product-title">{name || t('noName')}</h1>
          <div className="product-description">
            {renderRichTextContent(description)}
          </div>
        </div>
      </div>
      <div className="product-info-tabs mt-4">
        <div className="tabs d-flex mb-3 flex-wrap">
          <button
            className={`tab-button ${activeTab === 'properties' ? 'active' : ''}`}
            onClick={() => setActiveTab('properties')}
          >
            {t('properties')}
          </button>
          <button
            className={`tab-button ${activeTab === 'addinfo' ? 'active' : ''}`}
            onClick={() => setActiveTab('addinfo')}
          >
            {t('addInfo')}
          </button>
        </div>
        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
